import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import LoginForm from "../../components/LoginForm/LoginForm";
import { useTranslation } from 'react-i18next';
import JivoButton from "../../components/JivoButton";
import { ImageContainer } from './Components';

const Index = ({ setAuth }) => {
  const { t } = useTranslation('loginPage');
  const [showJivoButton, setShowJivoButton] = useState(true);

  const handleHideJivoButton = () => {
    setShowJivoButton(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <LoginForm setAuth={setAuth} />
      <ImageContainer
        style={{
          paddingTop:30
        }}
      >
        <img
          src="/assets/Vacina_GSK.png"
          alt="logo passe verde"
          width="200"
        />
      </ImageContainer>

      {showJivoButton && <JivoButton onClickCloseButton={handleHideJivoButton} />}
    </>
  );
}

export default Index;
