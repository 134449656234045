import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    boxShadow: '0px 3px 50px rgba(0,0,0,0.16)',
    paddingTop: '2px',
    height: 'auto',
    backgroundColor: '#E6E6E6',
  },

  buttonRoot: {
    minWidth: '30px',
    textDecoration: 'none',
    transition: '.5s',
    paddingTop: 0,
    paddingBottom: 2,
  },

  selected: {
    textDecoration: 'none'
  },

  styledLink: {
    textDecoration: 'none',
  },

  iconOnly: {
    textDecoration: 'none',
    transform: 'translateY(-25%)',
    '& .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly': {
      padding: 0,
    }
  },

  magicButton: {
    minWidth: 50,
    maxWidth: 70,
  },

  magicButtonImg: {
    width: 65,
    height: 65,
  },

  imgIcon: {
    width: 35,
  }
});

const BottomMenu = ({ pathname }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const pathIndex = {
    '/secure': 0,
    '/secure/': 0,
    '/secure/passe-verde': 1,
    '/secure/passe-verde/': 1,
    '/secure/get-vaccines': 2,
    '/secure/get-vaccines/': 2,
    '/secure/my-card': 3,
    '/secure/my-card/': 3,
    '/secure/digital-certificates': 4,
    '/secure/digital-certificates/': 4,
  }

  const getClassesByIndex = (buttonIndex) => {
    return pathIndex[pathname] === buttonIndex ?
      [classes.buttonRoot, classes.selected].join(' ') :
      classes.buttonRoot
  }

  return (
    <BottomNavigation
      className={classes.root}
    >
      <Link to="/secure" className={classes.styledLink}>
        <BottomNavigationAction
          className={getClassesByIndex(0)}
          icon={<img alt="" src={"/assets/hexagon-home-icon.svg"} className={classes.imgIcon} />}
          label={t('BOTTOM_NAVIGATION_HOME')}
          showLabel
        />
      </Link>
      <Link to="/secure/accounts" className={classes.styledLink}>
        <BottomNavigationAction
          className={getClassesByIndex(1)}
          icon={<img alt="" src={"/assets/hex_fam.svg"} className={classes.imgIcon} />}
          label="Família"
          showLabel
          style={{ padding: 0, paddingBottom: 2, width: 60 }}
        />
      </Link>
      <Link to="/secure/sitemap" className={classes.iconOnly}>
        <BottomNavigationAction
          className={classes.magicButton}
          icon={
            <img alt=""
              src={"/assets/magic-button-immunie3.svg"}
              className={classes.magicButtonImg} />
          }
        />
      </Link>
      <Link to="/secure/my-card" className={classes.styledLink}>
        <BottomNavigationAction
          className={getClassesByIndex(3)}
          icon={<img alt="" src={"/assets/hexagon-vaccine-icon.svg"} className={classes.imgIcon} />}
          label={t('BOTTOM_NAVIGATION_VACCINES')}
          showLabel
        />
      </Link>
      <Link to="/secure/digital-certificates" className={classes.styledLink}>
        <BottomNavigationAction
          className={getClassesByIndex(4)}
          icon={<img alt="" src={"/assets/hexagon-exam-icon.svg"} className={classes.imgIcon} />}
          label={t('BOTTOM_NAVIGATION_EXAMS')}
          showLabel
        />
      </Link>
    </BottomNavigation >
  );
}

export default BottomMenu;