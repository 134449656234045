import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Title, FormContainer } from "./Components";
import { getUser } from "../../utils/user";
import moment from "moment";
import Header from "../../components/Header/Header";
import { RedirectButton } from "../../components/RedirectButton";
import { FeedbackModal } from "../../components/FeedbackModal/FeedbackModal";

const MyVaccinationsCard = () => {
  const { t } = useTranslation("vaccinationsCardPage");

  const user = getUser();
  const today = moment();
  const birthdate = moment(user.birthday, "DD/MM/YYYY");
  const age = today.diff(birthdate, "years");
  const isUnder18 = age < 18;
  const [isOpenUnderAgeModal, setIsOpenUnderAgeModal] = useState(!isUnder18);

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>{t("TITLE")}</Title>

      <FormContainer>
        <RedirectButton
          title={t("UPLOAD_VACCINATIONS_CARD")}
          href="/secure/my-health/my-vaccinations-card/upload-vaccinations-card"
          marginBottom="20px"
        />

        <RedirectButton
          title={t("CONSULT_VACCINATIONS_CARD")}
          href="/secure/my-health/my-vaccinations-card/consult-vaccinations-card"
        />
      </FormContainer>

      <FeedbackModal
        id="plan-error"
        open={isOpenUnderAgeModal}
        title={{
          text: t("UPLOAD_VACCINATIONS_CARD_MODAL_TITLE"),
        }}
        description={{
          // text: t('UPLOAD_VACCINATIONS_CARD_ERROR_MODAL_CONTENT'),
          text: "A funcionalidade de envio de cartões de vacinas está disponível apenas para crianças e adolescentes neste momento. Gostaria que esta comodidade também esteja disponível para adultos ?",
        }}
        button={{
          text: "Sim, me avise quando estiver",
        }}
        extraButton="Não, obrigado"
        onClick={() => {
          setIsOpenUnderAgeModal(false);
          window.location.replace("/secure/accounts");
        }}
      />
    </Container>
  );
};

export default MyVaccinationsCard;
