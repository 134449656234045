import React from "react";
import Header from "../../components/Header/Header";
import {
  Container,
  Title,
  Body
} from "./Components.js";
import {
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import { getUser } from "../../utils/user";


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: 35,
    flexDirection: 'column',
  },

  province: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    marginBottom: 30,
    width: '100%',
  },

  language: {
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    margin: theme.spacing(2, 0),
  },

  regionDescription: {
    textAlign: 'justify',
    fontSize: '14px',
  },

  updatedAt: {
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

const CreateSubAccount = (props) => {
  const classes = useStyles();
  const user = getUser();

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>Contas</Title>
      <Body>
        <Box className={classes.container}>
          {
            user.isDependant ?
              <Typography>Operação não permitida para sub-contas</Typography> :
              <RegistrationForm responsible={user} setAuth={() => { }} country="S1" type="sub-account" />
          }
        </Box>
      </Body>
    </Container>
  );
}

export default CreateSubAccount;
