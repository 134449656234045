import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { dateConfig } from "../../../utils/dateConfig";
import {
  FormContainer,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  Title,
  CenterColumn,
} from "./Components";
import {
  Link,
  Modal,
  Fade,
  Backdrop,
  Typography,
  TextField,
  FormControl,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import {
  AccountCircle,
} from "@material-ui/icons/";
import moment from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import pt from "date-fns/locale/pt";
import DateFnsUtils from "@date-io/date-fns";
import CalendarIcon from "@material-ui/icons/Event";
import DatePicker from "react-mobile-datepicker";
import axios from 'axios'


const useStyles = makeStyles((theme) => ({
  input: {
    width: "95%",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 300,
    margin: "0 auto",
    marginTop: "400px",
  },

  keyboardDatePicker: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 14,
    '& .MuiIconButton-root': {
      display: 'none'
    },
  },

  datepickerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  datepicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    '& .datepicker': {
      position: 'static',
      borderRadius: 10,
      padding: 15,
    },
    '& .datepicker.default .datepicker-header': {
      fontSize: 20
    },
    '& .datepicker.default .datepicker-navbar-btn': {
      fontSize: 18
    }
  },
}));

const RegistrationForm = ({ setAuth, responsible, country, eventCode, type }) => {
  const { t } = useTranslation('registerPage');

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);


  const classes = useStyles();

  // Fields
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState({ date: new Date("01/01/2000"), touched: false });


  const changeBirthday = (value) => {
    const newBirthday = { ...birthday };
    newBirthday.date = value;
    newBirthday.touched = true;

    setBirthday(newBirthday);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!name) {
      setFeedback(t('VALIDATION_COMPLETE_ALL_FIELDS_ERROR'));
      setDialogOpen(true);
      return;
    }

    if (!birthday.touched) {
      setFeedback(t('VALIDATION_BIRTHDATE_ERROR'));
      setDialogOpen(true);
      return;
    }

    await handleRequest();
  };

  const handleRequest = async () => {
    try {
      setLoading(true);

      const jwt = localStorage.getItem('__session');
      const formatedJwt = (jwt || '').replace(/['"]+/g, '');
      console.log("DATA, ", {
        name: name,
        birthday: moment(birthday.date).format("DD/MM/YYYY"),
        country: country,
        eventCode: eventCode,
        registerType: type,
      })
      await axios.post(`${process.env.REACT_APP_API_URL}/client-app/register`,
        {
          name: name,
          birthday: moment(birthday.date).format("DD/MM/YYYY"),
          country: country,
          eventCode: eventCode,
          registerType: type,
          source: "GSK",
          responsible_name: responsible.name,
          responsible_email: responsible.email,
        }
        , {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${formatedJwt}`,
          }
        });


      // localStorage.setItem("__session", response.data.jwt);

      alert("Conta Criada!");
      const redirect = (to) => {
        history.push(to);
      };
      redirect.bind(this, "/secure/accounts")()
      // setUser(response.data?.user);
      // setAuth(true);
      // setRegistered(true);
    } catch (error) {
      const { isAxiosError } = error;

      if (isAxiosError) {
        if (error.response.data.message === "Email already taken") {
          alert(t('VALIDATION_EMAIL_ALREADY_USED_ERROR'));
        }
        if (error.response.data.message === "All tickets sold") {
          alert(t('VALIDATION_TICKETS_SOLD_ERROR'));
        }
        if (error.response.data.message === "Invalid code") {
          alert(t('VALIDATION_CODE_ERROR'));
        }
        setFeedback(error.response.data.message);
        setLoading(false);
      } else {
        alert(t('RECOVERY_EMAIL_MESSAGE'));
        setFeedback(t('SUBMIT_ERROR'));
      }

      console.log(`[RegistrationForm Error]: ${error}`);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div>
      <FormContainer>
        <Title>Crie a conta do seu dependente</Title>
        <FieldContainer>
          <AccountCircle fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <TextField
                label={t('NAME')}
                value={name}
                onChange={(event) => setName(event.target.value)}
                disabled={loading}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>
        <FieldContainer>
          <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
            <CalendarIcon fontSize="large" htmlColor="#5D5D5D" />
            <KeyboardDatePicker
              className={classes.keyboardDatePicker}
              margin="normal"
              fullWidth
              defaultValue={null}
              disableFuture
              id="date-picker-dialog"
              onClick={() => setOpenDatePicker(true)}
              label={t('BIRTHDATE')}
              format="dd/MM/yyyy"
              disabled={loading}
              value={birthday.date}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </FieldContainer>
        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <>
            <ButtonsContainer>
              <MainButton onClick={onSubmit}>
                <Typography style={{ fontWeight: "bold" }}>
                  Criar Nova Conta
                </Typography>
              </MainButton>
            </ButtonsContainer>
            <Link href="/secure/accounts">
              <Typography style={{ textAlign: "center", marginTop: 10 }}>
                Voltar para gestão do grupo
              </Typography>
            </Link>
          </>
        )}
      </FormContainer>
      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>

      <Modal
        aria-labelledby="birthdate-modal-title"
        aria-describedby="birthdate-modal-description"
        className={classes.datepickerModal}
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDatePicker}>
          <div className={classes.datepicker}>
            <DatePicker
              value={birthday.date}
              onSelect={(time) => {
                changeBirthday(time);
                setOpenDatePicker(false);
              }}
              onCancel={() => setOpenDatePicker(false)}
              isPopup={false}
              showHeader={true}
              headerFormat="DD/MM/YYYY"
              dateConfig={dateConfig}
              min={new Date(1910, 0, 1)}
              max={moment()._d}
              confirmText={t('BIRTHDATE_MODAL_CONFIRM_BUTTON')}
              cancelText={t('BIRTHDATE_MODAL_CANCEL_BUTTON')}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default RegistrationForm;