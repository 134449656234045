import React, {
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import { VoegolRegisterUpdateForm } from "../../components/VoegolRegisterUpdateForm";
import JivoButton from "../../components/JivoButton";

const VoegolRegisterUpdate = ({ setAuth }) => {
  const { t } = useTranslation('voeGolRegisterUpdatePage');
  const location = useLocation();
  const [showJivoButton, setShowJivoButton] = useState(true);

  const handleHideJivoButton = () => {
    setShowJivoButton(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <VoegolRegisterUpdateForm
        setAuth={setAuth}
        temporaryJwt={location.state?.temporaryJwt}
        countryDestination={location.state?.destination}
        nextTripDate={location.state?.nextTripDate}
      />

      {showJivoButton && <JivoButton onClickCloseButton={handleHideJivoButton} />}
    </>
  );
}

export default VoegolRegisterUpdate;
