import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import axios from "axios";
import { setUser } from "../../../utils/user";
import { validateEmail } from "../../../utils/validator";
import { dateConfig } from "../../../utils/dateConfig";
import ReactInputMask from "react-input-mask";
import {
  FormContainer,
  FieldContainer,
  TextFieldContainer,
  ButtonsContainer,
  MainButton,
  Title,
  CenterColumn,
} from "./Components";
import {
  Link,
  InputLabel,
  IconButton,
  InputAdornment,
  Input,
  Modal,
  Fade,
  Backdrop,
  Typography,
  TextField,
  FormControl,
  CircularProgress,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import {
  AccountCircle,
  AlternateEmail,
  Language,
  AssignmentInd,
  School
} from "@material-ui/icons/";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/Lock";
import moment from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import pt from "date-fns/locale/pt";
import DateFnsUtils from "@date-io/date-fns";
import CalendarIcon from "@material-ui/icons/Event";
import DatePicker from "react-mobile-datepicker";


const useStyles = makeStyles((theme) => ({
  input: {
    width: "95%",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 300,
    margin: "0 auto",
    marginTop: "400px",
  },

  keyboardDatePicker: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 14,
    '& .MuiIconButton-root': {
      display: 'none'
    },
  },

  datepickerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    maxWidth: '420px',
    margin: '0 auto',
  },

  datepicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    '& .datepicker': {
      position: 'static',
      borderRadius: 10,
      padding: 15,
    },
    '& .datepicker.default .datepicker-header': {
      fontSize: 20
    },
    '& .datepicker.default .datepicker-navbar-btn': {
      fontSize: 18
    }
  },
}));

const RegistrationForm = ({ setAuth, country, eventCode, type }) => {
  const { t } = useTranslation('registerPage');

  const isSchoolRegistry = type === "open-schools"

  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isPassport, setIsPassport] = useState(false);
  const [plansOptions, setPlansOptions] = useState([]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  // Fields
  const [name, setName] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [plan, setPlan] = useState();
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState({ date: new Date("01/01/2000"), touched: false });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSwitchDocType = () => {
    setIsPassport(!isPassport);
    setDocNumber("");
  }

  const changeBirthday = (value) => {
    const newBirthday = { ...birthday };
    newBirthday.date = value;
    newBirthday.touched = true;

    setBirthday(newBirthday);
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const { data: result } = await axios.get(
          `${process.env.REACT_APP_API_URL}/plans?familyPlan=true`
        );
        setPlansOptions(result)
      } catch (error) {
        console.error(error)
      }
    }
    fetchPlans()
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!name || !email || !password || !confirmPassword) {
      setFeedback(t('VALIDATION_COMPLETE_ALL_FIELDS_ERROR'));
      setDialogOpen(true);
      return;
    }

    if (!birthday.touched) {
      setFeedback(t('VALIDATION_BIRTHDATE_ERROR'));
      setDialogOpen(true);
      return;
    }

    if (!plan) {
      setFeedback("Por favor selecionar uma escola");
      setDialogOpen(true);
      return;
    }

    if (password !== confirmPassword) {
      setFeedback(t('VALIDATION_PASSWORD_MUST_BE_EQUAL_ERROR'));
      setDialogOpen(true);
      return;
    }

    if (!isPassport) {
      // eslint-disable-next-line
      if (!/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(docNumber)) {
        setFeedback(t('VALIDATION_CPF_NUMBER_ERROR'));
        setDialogOpen(true);
        return;
      }
    }

    if (!validateEmail(email)) {
      setFeedback(t('VALIDATION_EMAIL_ERROR'));
      setDialogOpen(true);
      return;
    }

    await handleRequest();
  };

  const handleRequest = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client-app/register`,
        {
          name: name,
          birthday: moment(birthday.date).format("DD/MM/YYYY"),
          email: email,
          password: password,
          documentType: isPassport ? 'passport' : 'cpf',
          documentNumber: docNumber,
          country: country,
          eventCode: eventCode,
          registerType: type,
          plan: plan,
          source: "GSK"
        }
      );

      localStorage.setItem("__session", response.data.jwt);

      setUser(response.data?.user);
      setAuth(true);
      setRegistered(true);
    } catch (error) {
      const { isAxiosError } = error;

      if (isAxiosError) {
        if (error.response.data.message === "Email already taken") {
          alert(t('VALIDATION_EMAIL_ALREADY_USED_ERROR'));
        }
        if (error.response.data.message === "All tickets sold") {
          alert(t('VALIDATION_TICKETS_SOLD_ERROR'));
        }
        if (error.response.data.message === "Invalid code") {
          alert(t('VALIDATION_CODE_ERROR'));
        }
        setFeedback(error.response.data.message);
        setLoading(false);
      } else {
        alert(t('RECOVERY_EMAIL_MESSAGE'));
        setFeedback(t('SUBMIT_ERROR'));
      }

      console.log(`[RegistrationForm Error]: ${error}`);
    }
  };

  return (
    <div>
      {registered && <Redirect to={"/"} />}

      <FormContainer>
        <Title>{t('SIGN_UP_DETAILS')}</Title>
        {
          isSchoolRegistry ?
            <FieldContainer>
              <School fontSize="large" htmlColor="#5D5D5D" />
              <TextFieldContainer>
                <FormControl className={classes.input}>
                  <InputLabel>Escola</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={plan}
                    onChange={(event) => setPlan(event.target.value)}
                    label="Escola"
                  >
                    {
                      plansOptions.map(p => (

                        <MenuItem value={p._id}>{p.displayName}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </TextFieldContainer>
            </FieldContainer> : null
        }
        <FieldContainer>
          <AccountCircle fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <TextField
                label="Nome Completo do Responsável"
                value={name}
                onChange={(event) => setName(event.target.value)}
                disabled={loading}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>
        <FieldContainer>
          <AlternateEmail fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <TextField
                label={t('EMAIL')}
                value={email}
                onChange={(event) =>
                  setEmail(event.target.value?.toLocaleLowerCase())
                }
                disabled={loading}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>
        <FieldContainer>
          <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
            <CalendarIcon fontSize="large" htmlColor="#5D5D5D" />
            <KeyboardDatePicker
              className={classes.keyboardDatePicker}
              margin="normal"
              fullWidth
              defaultValue={null}
              disableFuture
              id="date-picker-dialog"
              onClick={() => setOpenDatePicker(true)}
              label="Data de Nascimento do Responsável"
              format="dd/MM/yyyy"
              disabled={loading}
              value={birthday.date}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </FieldContainer>
        <FieldContainer>
          <IconButton onClick={handleSwitchDocType} style={{ padding: 0 }}>
            {isPassport ?
              <Language fontSize="large" htmlColor="#5D5D5D" />
              :
              <AssignmentInd fontSize="large" htmlColor="#5D5D5D" />
            }
          </IconButton>
          {
            isPassport ?
              <FormControl
                className={classes.input}
                style={{ padding: "0px 14px 8px 8px" }}
                value={docNumber}
                onChange={(event) => setDocNumber(event.target.value)}
              >
                <TextField
                  label={t('PASSPORT')}
                  disabled={loading} />
              </FormControl>
              :
              <ReactInputMask
                mask="999.999.999-99"
                value={docNumber}
                onChange={(event) => setDocNumber(event.target.value)}
              >
                {() => (
                  <FormControl
                    className={classes.input}
                    style={{ padding: "0px 14px 8px 8px" }}
                  >
                    <TextField
                      label={t('DOCUMENT_TYPE')}
                      disabled={loading}
                    />
                  </FormControl>
                )}
              </ReactInputMask>
          }
        </FieldContainer>
        <FieldContainer>
          <LockIcon fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-password">
                {t('PASSWORD')}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                autocomplete="off"
                label={t('PASSWORD')}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: "off",
                  "aria-autocomplete": "off",
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>
        <FieldContainer marginBottom="20px">
          <LockIcon fontSize="large" htmlColor="#5D5D5D" />
          <TextFieldContainer>
            <FormControl className={classes.input}>
              <InputLabel htmlFor="standard-adornment-password-c">
                {t('PASSWORD_CONFIRMATION')}
              </InputLabel>
              <Input
                id="standard-adornment-password-c"
                autocomplete="off"
                label={
                  t('PASSWORD_CONFIRMATION')
                }
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  autocomplete: "off",
                  "aria-autocomplete": "off",
                }}
              />
            </FormControl>
          </TextFieldContainer>
        </FieldContainer>
        {loading ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <>
            <ButtonsContainer>
              <MainButton onClick={onSubmit}>
                <Typography style={{ fontWeight: "bold" }}>
                  {t('SUBMIT')}
                </Typography>
              </MainButton>
            </ButtonsContainer>
            <Link href="/">
              <Typography style={{ textAlign: "center", marginTop: 10 }}>
                {t('ALREADY_HAVE_AN_ACCOUNT')}
              </Typography>
            </Link>
          </>
        )}
      </FormContainer>
      <Dialog open={dialogOpen} closeDialogHandler={setDialogOpen}>
        {feedback}
      </Dialog>

      <Modal
        aria-labelledby="birthdate-modal-title"
        aria-describedby="birthdate-modal-description"
        className={classes.datepickerModal}
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDatePicker}>
          <div className={classes.datepicker}>
            <DatePicker
              value={birthday.date}
              onSelect={(time) => {
                changeBirthday(time);
                setOpenDatePicker(false);
              }}
              onCancel={() => setOpenDatePicker(false)}
              isPopup={false}
              showHeader={true}
              headerFormat="DD/MM/YYYY"
              dateConfig={dateConfig}
              min={new Date(1910, 0, 1)}
              max={moment()._d}
              confirmText={t('BIRTHDATE_MODAL_CONFIRM_BUTTON')}
              cancelText={t('BIRTHDATE_MODAL_CANCEL_BUTTON')}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default RegistrationForm;