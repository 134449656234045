import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import ForgotPasswordForm from "../../components/ForgotPasswordForm/ForgotPasswordForm";
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
  const { t } = useTranslation('forgetPasswordPage');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <ForgotPasswordForm />
    </>
  );
}

export default ForgotPassword;
