import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import {
  Container,
  Title,
  Body
} from "./Components.js";
import {
  Box,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  GroupAdd
} from "@material-ui/icons";
import { request } from "../../utils/request";
import { getUser, setUser } from "../../utils/user";


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: 35,
    flexDirection: 'column',
  },
  accountContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '23px',
    padding: '10px 15px',
    boxShadow: '1px 5px 10px 0 rgb(16 36 94 / 8%)',
    marginBottom: 16,
    backgroundColor: 'white',
    justifyContent: "center",
    minHeight: 45,
  },
  accountName: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '0px',
    marginBottom: ' 0px',
  },
  province: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    marginBottom: 30,
    width: '100%',
  },

  language: {
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    margin: theme.spacing(2, 0),
  },

  regionDescription: {
    textAlign: 'justify',
    fontSize: '14px',
  },

  updatedAt: {
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

const AccountsManager = (props) => {
  const classes = useStyles();
  const [accounts, setAccounts] = useState([]);
  const user = getUser();

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const { data: info } = await request.get("/client-app/accounts");
        setAccounts(info);
      } catch (error) {
        console.log("[Website] Error:", error);
      }
    };
    fetchAccounts();
  }, []);


  const handleAccountSwitch = async (account) => {
    //TODO: MAGIC GOES HERE
    localStorage.setItem('__session', account.jwt);
    setUser(account.user);
    alert(`Trocando para conta de ${account.user?.name}`);
    // now we need to redirect to home with reloading the page so the new user data is loaded
    window.location.replace("/secure");
  };

  return (
    <Container>
      <Header backgroundStyle="BLUE" />
      <Title>Família</Title>
      <Body>
        <Box className={classes.container}>
          <Typography>
            Gestão do grupo
          </Typography>
          <Typography>
          Selecione a conta de usuário que deseja acessar. Para atualizar as informações vacinais de sua família, selecione o membro do qual deseja realizar o upload do cartão vacinal. Para cadastrar um novo membro, clique no botão "Adicionar dependente”
          </Typography>
        </Box>
        {
          accounts.map(acc =>
            user._id === acc.user?.id ?
              <Box
                className={classes.accountContainer}
                key={acc.user?.id}
                onClick={() => handleAccountSwitch(acc)}
                style={{
                  cursor: "pointer",
                  border: "1px solid green"
                }}
              >
                <Typography
                  align="center"
                  className={classes.accountName}
                  style={{ color: "#2D80C6" }}
                >{acc.user?.name}</Typography>
              </Box> :

              <Box
                className={classes.accountContainer}
                key={acc.user?.id}
                onClick={() => handleAccountSwitch(acc)}
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  align="center"
                  className={classes.accountName}
                  style={{ color: "#2D80C6" }}
                >{acc.user?.name}</Typography>
              </Box>)
        }
        {user.isDependant ?
          null :
          <Box style={{ display: "flex", marginTop: 40 }}>
            <Button
              href="/secure/create-sub-account"
              startIcon={<GroupAdd />}
              style={{
                backgroundColor: "#3893D0",
                color: "white",
                margin: "auto"
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>Adicionar Dependente</Typography>
            </Button>
          </Box>
        }
      </Body>
    </Container>
  );
}

export default AccountsManager;
