import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";
import { useTranslation } from 'react-i18next';

const Index = ({ setAuth }) => {
  const { t } = useTranslation('confirmPasswordPage');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        isHome={true}
        greeting={t('HEADER_TITLE')}
        subtitle={t('HEADER_SUBTITLE')}
      />
      <NewPasswordForm setAuth={setAuth} />
    </div>
  );
}

export default Index;
